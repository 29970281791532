<template>
  <div>
    <el-button
      type="info"
      :disabled="false"
      @click="showTheSizesDialog()"
      round
    >{{ numberOfSizes }} Sizes</el-button>
    <article>
      <section>
        <div class="size_dialogs">
          <el-dialog
            :title="`Sizes`"
            :visible.sync="isSizeModalVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div class="row">
              <div class="col-md-6">
                <hr />
                <div
                  v-loading="loading"
                  class="table-responsive mb-5"
                >
                  <data-tables
                    :data="sizes"
                    :table-props="tableProps"
                    :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                    type="expand"
                    :filters="filters"
                  >

                    <div slot="empty">
                      <ScaleOut
                        v-if="isLoading && !loadingError"
                        class="mt-3"
                        :background="'#164B70'"
                      />
                      <div
                        @click="getSizes"
                        v-else-if="!isLoading && loadingError"
                        style="cursor: pointer"
                      >
                        Unable to Load Sizes Now. Please click here to retry
                      </div>
                      <div v-if="!isLoading && !loadingError">No Sizes</div>
                    </div>

                    <el-table-column
                      label="#"
                      :show-overflow-tooltip="false"
                      label-class-name="text-center"
                      class-name="text-center"
                      width="80px"
                    >
                      <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="Size Name"
                      :show-overflow-tooltip="false"
                      label-class-name="text-left"
                      class-name="text-left"
                    >
                      <template slot-scope="scope">
                        <span> {{ scope.row.size_name }} </span><br />
                        <small style="font-size: 0.6em;">Add On: {{ formattedDate(scope.row.created_date) }} </small><br />
                        <small style="font-size: 0.6em;">Updated On: {{ formattedDate(scope.row.updated_date) }} </small>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="Actions"
                      :show-overflow-tooltip="false"
                      label-class-name="text-center"
                      class-name="text-center"
                    >
                      <template slot-scope="scope">
                        <el-row style="display: flex; align-items:center; justify-content:center;">
                          <el-tooltip
                            content="Edit"
                            placement="top"
                          >
                            <el-button
                              type="warning"
                              :disabled="false"
                              @click="showEditSizeData(scope.row)"
                              icon="el-icon-edit"
                              circle
                            ></el-button>
                          </el-tooltip>
                          <el-tooltip
                            content="Delete"
                            placement="top"
                          >
                            <el-button
                              type="danger"
                              :disabled="false"
                              @click="deleteSize(scope.row.size_id)"
                              icon="el-icon-delete"
                              circle
                            ></el-button>
                          </el-tooltip>
                        </el-row>
                      </template>
                    </el-table-column>

                  </data-tables>
                </div>
              </div>
              <div class="col-md-6">
                <div v-loading="submitting">
                  <div v-if="size_crud_field == 'add'">
                    <h2 style="font-size:1.5em">Add Size</h2>
                    <hr />
                    <el-form
                      :model="sizeForm"
                      :rules="rules"
                      label-position="top"
                      ref="sizeForm"
                      class="demo-sizeForm2"
                      onSubmit="return false;"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <el-form-item
                            label="Size Name"
                            prop="size_name"
                          >
                            <el-input
                              v-model="sizeForm.size_name"
                              @keyup.enter.native="addSize('sizeForm')"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <hr />
                      <el-form-item class="text-center">
                        <el-button
                          style="background-color: #083628; color: white;"
                          @click="addSize('sizeForm')"
                        > Add Size</el-button>
                      </el-form-item>
                    </el-form>
                    <div class="text-center">
                      <div class="text-center">
                        <small>© 2022 Everythingirly, All rights reserved.</small>
                      </div>
                      <div class="text-center">
                        <small>
                          Design by <a
                            href="https://ovalspace.co"
                            style="color: black;"
                          ><strong>Ovalspace</strong></a>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="size_crud_field == 'update'">
                    <div class="d-flex align-items-center justify-content-between">
                      <h2 style="font-size:1.5em">Edit Size</h2>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="size_crud_field = 'add'"
                      >Back To Add Size<i class="el-icon-arrow-right el-icon-right"></i></el-button>
                    </div>
                    <hr />
                    <el-form
                      :model="sizeEditForm"
                      :rules="rules"
                      label-position="top"
                      ref="sizeEditForm"
                      class="demo-sizeEditForm2"
                      onSubmit="return false;"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <el-form-item
                            label="Size Name"
                            prop="size_name"
                          >
                            <el-input
                              v-model="sizeEditForm.size_name"
                              @keyup.enter.native="editSize('sizeEditForm')"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <hr />
                      <el-form-item class="text-center">
                        <el-button
                          style="background-color: #083628; color: white;"
                          @click="editSize('sizeEditForm')"
                        >Edit Size</el-button>
                      </el-form-item>
                    </el-form>
                    <div class="text-center">
                      <div class="text-center">
                        <small>© 2022 Everythingirly, All rights reserved.</small>
                      </div>
                      <div class="text-center">
                        <small>
                          Design by <a
                            href="https://ovalspace.co"
                            style="color: black;"
                          ><strong>Ovalspace</strong></a>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
  },
  data() {
    return {
      loading: false,
      isSizeModalVisible: false,
      submitting: false,
      sizes: [],
      size_crud_field: "add",
      sizeData: {},
      tableProps: {
        border: true,
        stripe: true,
      },
      filters: [
        {
          prop: "size_name",
          value: "",
        },
      ],
      loadingError: false,
      size_edit_id: "",
      sizeForm: {
        size_name: "",
      },
      sizeEditForm: {
        size_id: "",
        size_name: "",
      },
      rules: {
        size_name: [
          {
            required: true,
            message: "Size Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    numberOfSizes: {
      required: true,
      type: Number,
    },
    productId: {
      required: true,
      type: Number,
    },
    categoryId: {
      required: true,
      type: String,
    },
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },
  methods: {
    handleClose(done) {
      done();
    },

    showTheSizesDialog() {
      this.isSizeModalVisible = true;
      this.getSizes();
    },

    showEditSizeData(sizeData) {
      this.size_crud_field = "update";
      this.sizeData = sizeData;
      this.size_edit_id = sizeData.size_id;
      this.sizeEditForm.size_name = sizeData.size_name;
    },

    async addSize(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.post(`sizes`, {
              product_id: this.productId,
              size_name: this.sizeForm.size_name,
              created_by: this.$store.state.userId,
              category_id: this.categoryId
            });
            if (
              response.data.success &&
              response.data.message == "SIZE_CREATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$notify({
                title: "Success",
                message: "Size Added Successfully",
                type: "success",
              });
              //   this.isSizeModalVisible = false;
              this.$emit("re-fresh");
              this.getSizes();
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Add Size",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    async getSizes() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`sizes/${this.productId}`);
        if (request.data.success) {
          this.sizes = request.data.sizes;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch sizes now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async editSize(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.patch(
              `sizes/${this.size_edit_id}`,
              {
                size_name: this.sizeEditForm.size_name,
              }
            );
            if (
              response.data.success &&
              response.data.message == "SIZE_UPDATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$notify({
                title: "Success",
                message: "Size Updated Successfully",
                type: "success",
              });
              this.size_crud_field = "add";
              //   this.isSizeModalVisible = false;
              this.$emit("re-fresh");
              this.getSizes();
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Add Size",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    async deleteSize(size_id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Size. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`sizes/${size_id}`);
            if (
              request.data.success &&
              request.data.message === "SIZE_DELETED_SUCCESSFULLY"
            ) {
              this.$emit("re-fresh");
              this.getSizes();
              this.$notify({
                title: "Success",
                message: "Size deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete the Size, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>
<style>
.size_dialogs .el-dialog {
  width: 80%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
</style>